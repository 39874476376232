<template>
  <v-container id="crud" fluid tag="section">

    <v-row  >
      <v-col cols="12" md="12" class="py-0">
        <v-card elevation="0">
          <v-card-title>Busqueda de clientes</v-card-title>
          <br>
          <v-card-text>
              <v-row class="">
                <v-col cols="12" sm="3">
                  <label for="">Cuit o Pasaporte</label>
                  <v-text-field
                  v-model="searchCuit"
                  dense
                  outlined
                  hint="Ingrese cuit o pasaporte completo"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <label for="">Nombre o Razon Social</label>
                  <v-text-field
                  v-model="searchName"
                  dense
                  outlined

                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1" class="d-flex align-center pt-1">
                  <v-btn
                    color="primary"
                    small
                    class="capitalize-first custom-btn"
                    @click="buscar()"
                      >Buscar
                    <v-icon right>mdi-magnify</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="2" class="d-flex align-center pt-1">
                  <v-btn
                    color="success"
                    small
                    @click="nuevoCliente()"
                    class="capitalize-first custom-btn"
                      >Nuevo cliente
                    <v-icon right>mdi-account-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12"  class="pt-0">
       <v-data-table
          :headers="headersClientes"
          :items="clientes"
          class="elevation-0 data__table"
          :footer-props="{
            'items-per-page-options': [10, 20],
          }"
          :item-class="getItemClass"
        >



        <template v-slot:no-data>
        <div class="text-center">
          <br><br>
          <v-icon color="primary" style="font-size: 65px;">mdi-magnify</v-icon>
          <p class="mt-2">Aún no hay datos para mostrar. Realiza una búsqueda utilizando<br> los campos anteriores</p>

        </div>
      </template>

         <template v-slot:[`item.CardName`]="{ item }">
           {{ formatValue(item.CardName) }}
         </template>
         <template v-slot:[`item.FederalTaxID`]="{ item }">
           {{ formatValue(item.FederalTaxID) }}
         </template>
         <template v-slot:[`item.City`]="{ item }">
           {{ formatValue(item.City) }}
         </template>
         <template v-slot:[`item.Cellular`]="{ item }">
           {{ formatValue(item.Cellular) }}
         </template>
         <template v-slot:[`item.EmailAddress`]="{ item }">
           {{ formatValue(item.EmailAddress) }}
         </template>

      <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            color="black"
            title="Ver "
            @click="verCliente(item)"
          >
            mdi-eye
          </v-icon>
          <v-icon
            v-if="item.active"
            color="black"
            title="Editar"
             @click="editarCliente(item)"
          >
            mdi-pencil
          </v-icon>

        </template>
      </v-data-table>


      </v-col>
    </v-row>


     <dialog-form-client
              v-model="dialogFormCliente"
              @input="dialogFormCliente = $event"
              :form="form"
              :errors="errors"
              :disabled="disabled"
              @close="closeDialog"
              :mode="mode"
              :db="$db('ANMAC')"
            />

  </v-container>
</template>

<script>
import DialogFormClient from "@/views/dialogs/FormClient.vue";

export default {
   components: {

    DialogFormClient,
  },
  name: "ClientesAduana",
  data() {
    return {

      searchCuit: "",
      searchName: "",
      headersClientes: [

        { text: "Nombre", value: "CardName" },
        { text: "Documento", value: "FederalTaxID" },
        { text: "Ciudad", value: "City" },
        { text: "Celular", value: "Cellular" },
        { text: "Mail", value: "EmailAddress" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      clientes: [],
      apiRoute: "api/hanaxs/bussinesspartners/clientes/search",
      clienteSelected:[],
      dialogFormCliente:false,

      errors:{},
      mode: 'create',
      disabled: true,
      form: {
        tipoDocumento:  null,
        numeroDocumento:  null,
        nombreApellido:  null,
        calle:  null,
        numero:  null,
        piso:  null,
        depto:  null,
        pais:  null,
        provincia:  null,
        localidad: null,
        cp:  null,
        telefono: null,
        celular:null,
        mail: null
      }
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {

    nuevoCliente(){

      this.form= {
        tipoDocumento:  null,
        numeroDocumento:  null,
        nombreApellido:  null,
        calle:  null,
        numero:  null,
        piso:  null,
        depto:  null,
        pais:  null,
        provincia:  null,
        localidad: null,
        cp:  null,
        telefono: null,
        celular:null,
        mail: null
      };
      this.mode = 'create';
      this.errors={};
      this.customErrorMessage = "";
      this.disabled=false;
      this.dialogFormCliente=true;
    },

    async editarCliente(item) {
      this.errors = {}
      let locationInternalCode = await this.$getLocationInternalCode(item.BPAddresses[0]?.State);
      let td = Number(item.U_B1SYS_FiscIdType);
      this.form = {
        tipoDocumento: td, // Establecemos "Cuit" como tipo de documento
        numeroDocumento: item.FederalTaxID || "", // Número de documento
        nombreApellido: item.CardName || "", // Nombre y Apellido
        cardCode: item.CardCode || "", // Cardcode
        calle: item.BPAddresses[0]?.Street || "", // Calle
        numero: item.BPAddresses[0]?.StreetNo || "", // Número
        piso: item.BPAddresses[0]?.Building || "", // Piso
        depto: item.BPAddresses[0]?.Block || "", // Depto
        pais: item.BPAddresses[0]?.Country || "", // País
        provincia: locationInternalCode || "", // Provincia
        ciudad: item.BPAddresses[0]?.City || "", // Localidad
        rowNum: item.BPAddresses[0]?.RowNum ?? null, // Número de row a actualizar
        addressName: item.BPAddresses[0]?.AddressName ?? null, // Nombre de address
        cp: item.BPAddresses[0]?.ZipCode || "", // Código postal
        telefono: item.Phone1 || "", // Teléfono
        celular: item.Cellular || "", // Celular
        mail: item.EmailAddress || "" // Correo electrónico
      };
      this.mode = 'edit';
      this.disabled = false;
      this.disableSelect = true;
      this.dialogFormCliente = true;
    },
    async verCliente(item) {
      let locationInternalCode = await this.$getLocationInternalCode(item.BPAddresses[0]?.State);
      let td = Number(item.U_B1SYS_FiscIdType);
      console.log("td: ", td);
      this.form = {
        tipoDocumento: td, // Establecemos "Cuit" como tipo de documento
        numeroDocumento: item.FederalTaxID || "", // Número de documento
        nombreApellido: item.CardName || "", // Nombre y Apellido
        calle: item.BPAddresses[0]?.Street || "", // Calle
        numero: item.BPAddresses[0]?.StreetNo || "", // Número
        piso: item.BPAddresses[0]?.Building || "", // Piso
        depto: item.BPAddresses[0]?.Block || "", // Depto
        pais: item.BPAddresses[0]?.Country || "", // País
        provincia: locationInternalCode || "", // Provincia
        ciudad: item.BPAddresses[0]?.City || "", // Localidad
        cp: item.BPAddresses[0]?.ZipCode || "", // Código postal
        telefono: item.Phone1 || "", // Teléfono
        celular: item.Cellular || "", // Celular
        mail: item.EmailAddress || "" // Correo electrónico
      };
      this.mode = 'view';
      this.disabled = true;
      this.disableSelect = false;
      this.dialogFormCliente = true;
      this.errors = {}
    },

     formatValue(value) {
      return value !== null && value !== undefined && value !== "" ? value : "null";
    },
    closeDialog(data) {
      if (data) {
        this.searchCuit = data;
        this.searchName = "";
        this.buscar();
      }
    },

    fetchClientesAnmac(){

      Swal.alertGetInfo("Buscando información");

       setTimeout(() => {
          this.clientes =   [ ] ;

          Swal.close();
        },500);
    },

    buscar() {
      Swal.alertGetInfo("Buscando información");

      let url = `${this.apiRoute}/`+process.env.VUE_APP_DB_ANMAC;

      const params = [];
      if (this.searchCuit) {
        params.push(`documento=${encodeURIComponent(this.searchCuit)}`);
      }
      if (this.searchName) {
        params.push(`nombre=${encodeURIComponent(this.searchName.toUpperCase())}`);
      }

      // Agregar los parámetros a la URL
      if (params.length) {
        url += `?${params.join("&")}`;
      }

      this.$axiosApi
        .sendRequest(url, "get")
        .then((response) => {
          if (response.data && response.data.data) {
            this.clientes = response.data.data.map((cliente) => {
              const firstAddress = cliente.BPAddresses?.[0] || {};
              return {
                ...cliente,
                City: firstAddress.City || "null"
              };
            });
          } else {
            this.clientes = [];
          }
          Swal.close();
        })
        .catch((error) => {
          console.error("Error al buscar clientes:", error);
          Swal.close();
          Swal.fire("Error", "No se pudieron obtener los datos. Intenta nuevamente.", "error");
        });
    },

    getItemClass: function (item) {
       return item.active  === false ?  "inactive-item" :'';
    },

  },
  created() {

  },
  mounted() {

  },
};
</script>


<style scoped>
.capitalize-first {
  text-transform: lowercase; /* Convierte todo el texto a minúsculas */
}

.capitalize-first::first-letter {
  text-transform: uppercase; /* Capitaliza solo la primera letra */
}

.filtros-trans {
  margin-bottom: 4px;
}
.table-cell-trans {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 16px;
  text-align: left !important;
}
.mt-n4 {
  margin-top: -16px !important;
}
.mt-n5 {
  margin-top: -20px !important;
}
.text-red {
  color: red;
  font-weight: bold;
}
</style>
